import { Component, forwardRef, inject, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { GenerateRandom } from '../../utils/random';
import { catchError, debounceTime, lastValueFrom, of, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import queryString from 'qs';
import { ErrorHepler } from '../../utils/error-helper/error-helper';

interface APIParams {
  keywords: string | null;
  limit: number;
  offset: number;
  id?: number;
}

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrl: './select-search.component.scss',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectSearchComponent), multi: true }],
})
export class SelectSearchComponent implements OnInit, OnDestroy {
  @Input() label!: string;
  @Input() placeholder = 'Select option...';
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() isRequired = false;
  @Input() limit = 20;
  @Input() bindValue = 'id';
  @Input() bindLabel = 'name';
  @Input() debounceTime = 0;
  @Input()
  set options(value: any[]) {
    this.tmpOptions = value;
    this.optionItems = value;
    this.apiUrl = '';
  }
  @Input()
  set url(value: string) {
    this.apiUrl = value;
  }

  @Input()
  set error(value: any) {
    this.errorMessage = '';
    this.isError = false;

    if (value) {
      this.isError = true;
      this.errorMessage = ErrorHepler.errorMessage(value);
    }
  }

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
  }

  @Input()
  set params(value: any) {
    this.onParamsChange(value);
  }

  apiParams: APIParams = {
    keywords: '',
    limit: this.limit,
    offset: 0,
  };

  @Output() valueChange = new EventEmitter();
  @Output() valueItemChange = new EventEmitter();

  isLoadMore = true;

  id = `select-id-${GenerateRandom(10)}`;
  dsId = `ds-id-${GenerateRandom(10)}`;

  form = new FormControl();
  search = new FormControl();

  isHideOption = true;

  display: string | null = '';

  tmpOptions: any = [];
  optionItems: any = [];
  apiUrl!: string;
  errorMessage = '';
  isError = false;
  isDisable = false;

  loadRequset$ = new Subject();

  private http = inject(HttpClient);
  private subscription: Subscription = new Subscription();

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  ngOnInit(): void {
    const sb = this.form.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe((val) => {
      this.onChange(this.form.value);
      this.valueChange.emit(this.form.value);
      this.valueItemChange.emit(this.selectOption);
    });

    this.subscription.add(sb);
    this.onSearchChange();
    this.loadDataReducer();
    this.loadData();

    setTimeout(() => {
      this.detectcloseAfterOutsideTraget();
      this.defectScrollToEnd();

      // this.apiParams.limit = this.limit;
    }, 100);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async writeValue(value: any): Promise<void> {
    if (value) {
      setTimeout(() => {
        this.loadData(value);
      }, 50);
    } else {
      this.form.setValue(null, { emitEvent: false, onlySelf: true });
      this.display = null;
    }
  }

  setDisabledState?(isDisabled: boolean): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSearchChange() {
    const sb = this.search.valueChanges.pipe(debounceTime(200)).subscribe((val) => {
      if (this.apiUrl) {
        this.apiSearch(val);
      } else {
        this.localSearch(val);
      }
    });
    this.subscription.add(sb);
  }

  localSearch(keywords: string | null) {
    this.optionItems = this.tmpOptions.filter((item: any) =>
      `${item[this.bindLabel]}`.toLowerCase().includes(keywords || ''.toLowerCase())
    );
  }

  apiSearch(keywords: string | null) {
    this.apiParams = {
      ...this.apiParams,
      limit: this.limit,
      offset: 0,
      keywords,
    };
    this.optionItems = [];
    this.isLoadMore = true;

    this.loadData();
  }

  onParamsChange(params: any) {
    this.apiParams = {
      limit: this.limit,
      offset: 0,
      keywords: '',
      ...params,
    };

    this.optionItems = [];
    this.isLoadMore = true;

    this.loadData();
  }

  onSelectChange() {
    const sb = this.form.valueChanges.subscribe((res) => {
      this.onChange(res);
    });

    this.subscription.add(sb);
  }

  loadData(value?: any) {
    this.loadRequset$.next(value || false);
  }

  loadDataReducer() {
    const sb = this.loadRequset$.pipe(debounceTime(300)).subscribe(async (value) => {
      if (value) {
        if (this.optionItems.length === 0) {
          await this.loadDataEffect();
        }
        let found = this.optionItems.find((item: any) => item[this.bindValue] === value);
        if (!found) {
          this.apiParams.id = value as any;
          this.apiParams.offset = 0;
          await this.loadDataEffect();
          found = this.optionItems.find((item: any) => item[this.bindValue] === value);
          delete this.apiParams.id;
          this.isLoadMore = true;
        }

        this.form.setValue(found[this.bindValue], { emitEvent: false, onlySelf: true });
        this.display = found[this.bindLabel];
      } else {
        await this.loadDataEffect();
      }
    });

    this.subscription.add(sb);
  }

  async loadDataEffect() {
    const resp = await lastValueFrom(
      this.http
        .get<any[]>(`/${this.apiUrl}?${queryString.stringify({ filters: this.apiParams }, { encode: false })}`)
        .pipe(catchError(() => of([])))
    );

    if (resp.length < this.limit) {
      this.isLoadMore = false;
    }

    for (const item of resp) {
      if (!this.optionItems.some((i: any) => i[this.bindValue] === item[this.bindValue])) {
        this.optionItems.push(item);
      }
    }
  }

  toggleOption() {
    this.isHideOption = !this.isHideOption;
  }

  selectedOption(option: any) {
    this.form.setValue(option[this.bindValue]);
    this.isHideOption = true;
    this.display = option[this.bindLabel];
  }

  detectcloseAfterOutsideTraget() {
    document.addEventListener('click', ({ target }: any) => {
      if (!target?.closest(`#${this.id}`)) {
        this.isHideOption = true;
      }
    });
  }

  clearSelected() {
    this.form.setValue(null);
    this.display = null;
  }

  defectScrollToEnd() {
    const el: any = document.querySelector(`#${this.dsId}`);
    el?.addEventListener('scroll', (event: any) => {
      if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
        this.loadMore();
      }
    });
  }

  loadMore() {
    if (!this.isLoadMore) return;
    this.apiParams.offset += this.apiParams.limit;
    this.loadData();
  }

  get selectOption() {
    return this.optionItems.find((item: any) => item[this.bindValue] === this.form.value);
  }
}
